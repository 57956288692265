.MainBackground {
    background-color: #eaebef;
    /* position: fixed; */
    /* top: 140px; */
    position: relative;
    margin-top: -240px;
    left: 0;
    width: 100%;
    height: 100vh;
}


.SignUp {
    text-align: center;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    flex-direction: column;
    background-color:  #eaebef;
}

.Header{
    margin-top: 10px;
    color: #8e8a8af7;
}

.Logo {
    margin-top: 0px;
    align-items: left;
    /* height: 150px; */
    width: 150px;
    /* height: 90px;
    width: 3200px; */
}


hr {
    background-color: rgb(25, 24, 24);
    border-style: none;
    border-top-style: dotted;
    height: 0px;
    width: 100%;
    margin-bottom: 50px;
  }

.Border {
    text-align: center;
    display: flex; 
    /* justify-content: center;  */
    align-items: center; 
    flex-direction: column;
    background-color: #ffffff;
    margin-top: 60px;
    padding-bottom: 70px;
    padding-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 10px;
    border: 2px solid #ccc;
}

.InputClass {
    width: 350px;
    height: 40px;
    margin-top: 15px;
    font-size: 1.5rem;
    border-radius: 5px;
}

.Button {
    font-size: 15px ;
    margin-top: 20px;
    /* width: 350px; */
    width: 100%;
    height: 40px;
    background-color: #2b4252;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition-duration: 0.4s;
}

.Button:hover {
    background-color: #82909b;
    /* background-color: #13b5ea; */
    color: white;
  }