
// 
// preloader.scss
//


.loader {
    transform: rotateZ(45deg);
    perspective: 200px;
    border-radius: 50%;
    width: 148px;
    height: 148px;
    color: $white;
    position: relative;
    margin: 72px auto;    
  }

  .loader-light {
    color: $secondary;
  }
    .loader:before,
    .loader:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: inherit;
      height: inherit;
      border-radius: 50%;
      transform: rotateX(70deg);
      animation: 1s spin linear infinite;
    }
    .loader:after {
      color: $primary;
      transform: rotateY(70deg);
      animation-delay: .4s;
    }

  @keyframes rotate {
    0% {
      transform: translate(-50%, -50%) rotateZ(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotateZ(360deg);
    }
  }

  @keyframes rotateccw {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(-360deg);
    }
  }

  @keyframes spin {
    0%,
    100% {
      box-shadow: .2em 0px 0 0px currentcolor;
    }
    12% {
      box-shadow: .2em .2em 0 0 currentcolor;
    }
    25% {
      box-shadow: 0 .2em 0 0px currentcolor;
    }
    37% {
      box-shadow: -.2em .2em 0 0 currentcolor;
    }
    50% {
      box-shadow: -.2em 0 0 0 currentcolor;
    }
    62% {
      box-shadow: -.2em -.2em 0 0 currentcolor;
    }
    75% {
      box-shadow: 0px -.2em 0 0 currentcolor;
    }
    87% {
      box-shadow: .2em -.2em 0 0 currentcolor;
    }
  }


//   template css, but couldn't find actual spinner so chucked new one in
// #preloader {
//     position: fixed;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background-color: $primary;
//     z-index: 9999;
// }

// #status {
//     width: 40px;
//     height: 40px;
//     position: absolute;
//     left: 50%;
//     top: 50%;
//     margin: -20px 0 0 -20px;
// }

// .spinner {
//     margin: 0 auto;
//     font-size: 10px;
//     position: relative;
//     text-indent: -9999em;
//     border-top: 5px solid $gray-300;
//     border-right: 5px solid $gray-300;
//     border-bottom: 5px solid $gray-300;
//     border-left: 5px solid $primary;
//     transform: translateZ(0);
//     animation: SpinnerAnimation 1.1s infinite linear;
// }

// .spinner,
// .spinner:after {
//     border-radius: 50%;
//     width: 40px;
//     height: 40px;
// }

// @keyframes SpinnerAnimation {
//     0% {
//         transform: rotate(0deg);
//     }
//     100% {
//         transform: rotate(360deg);
//     }
// }